.timelineTable
  .timelineTableRow
    display: grid
    grid-template-columns: 100px 1fr
    gap: 70px
    &:nth-child(1)
      margin-bottom: 30px
    h1
      font-size: 27px
      font-weight: 600
      margin-bottom: 0
    .timelineCol:nth-child(2)
      margin-bottom: 60px
    .timelineLine
      width: 1px
      background-color: #707070
      height: calc(100% - 120px)
      margin: 0 auto
      margin-top: 10px
    .timelineYear
      border-radius: 50px
      width: 100px
      height: 100px
      display: flex
      justify-content: center
      align-items: center
      background-color: #F5EFEB

@media (max-width: 767px)
  .timelineTable
    .timelineTableRow
      grid-template-columns: 1fr
      gap: 20px
      text-align: center
      .timelineYear
        margin: 0 auto