.teamMembersWrapper
  margin-bottom: 80px
  .teamMembersContainer
    display: flex
    flex-wrap: wrap
    .teamMembersItem
      flex-basis: calc(25% - 30px)
      padding-right: 30px
      padding-bottom: 50px
      img
        width: 100%
        height: 350px
        object-fit: cover
        border-radius: 8px
        margin-bottom: 20px
      .teamMembersItemContent
        padding: 0 15px
        h3
          font-size: 14px
          margin-bottom: 20px
          span
            position: relative
            top: 5px
            font-size: 18px
    .teamMembersOverlay
      width: 100%
      height: 100%
      position: fixed
      top: 0
      left: 0
      right: 0
      bottom: 0
      z-index: 10
      display: none
      align-items: center
      justify-content: center
      .teamMembersOverlayContent
        width: 90%
        height: 80%
        max-height: 800px
        max-width: 1200px
        background-color: rgb(245, 239, 235)
        border-radius: 8px
        position: relative
        z-index: 1
        display: grid
        grid-template-columns: 40% 1fr
        align-items: center
        .teamMembersOverlayClose
          position: absolute
          top: 20px
          left: 20px
          color: #fff
        img
          width: 100%
          height: 100%
          margin-bottom: 0
        .teamMembersOverlayBlurb
          display: grid
          padding: 0 80px 0px 80px
          grid-template-columns: 1fr
          align-items: center
          justify-content: center
          height: 85%
          overflow-y: auto
          h3
            font-size: 14px
            margin-bottom: 20px
            span
              position: relative
              top: 5px
              font-size: 18px
      .teamMembersBackground
        width: 100%
        height: 100%
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: rgba(0,0,0, 0.6)

.teamMembersOverlay.active
  display: flex !important

@media ( max-width: 1050px )
  .teamMembersWrapper
    .teamMembersContainer
      .teamMembersItem
        flex-basis: calc(33% - 30px)

@media ( max-width: 900px )
  .teamMembersWrapper
    .teamMembersContainer
      .teamMembersItem
        flex-basis: calc(50% - 30px)

@media ( max-width: 769px )
  .teamMembersWrapper
    .teamMembersContainer
      .teamMembersItem
        .teamMembersOverlayContent
          max-height: 10000px
          grid-template-columns: 1fr
          grid-template-rows: 500px 1fr
          gap: 70px
          align-items: flex-start

@media ( max-width: 650px )
  .teamMembersWrapper
    .teamMembersContainer
      .teamMembersItem
        flex-basis: 100%