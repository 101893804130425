@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700")
  
h1, h2, h3
  font-family: "Montserrat", sans

.video-player-container
  position: relative
  height: 56.25vw
  margin-bottom: 100px
.video-player
  width: 100%
  height: 56.25vw
  position: absolute
  display: flex
  align-items: center
  clip: rect(0, auto, auto, 0)
  overflow: hidden
  iframe, img, .shadow
    width: 100vw
    height: 56.25vw
    min-height: 100vh
    min-width: 177.77vh
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%) scale(1.0006)
  .shadow
    background-color: #000
    opacity: 0.6
.banner-title
  position: absolute 
  top: 55%
  margin: 0
  // top: 35%
  left: 50px
  color: #fff
  // position: absolute
  z-index: 1
  text-transform: uppercase
  font-size: 600%
  font-weight: 800
  letter-spacing: 2px
  .large
    color: #fff
    font-size: 50px
    font-weight: normal
    letter-spacing: -2px
  .black
    // color: #000
.thin
  width: 95%
  margin: 0 auto
  margin-top: 100px
  height: 600px
  .video-player, iframe
    height: 600px
  .banner-title
    top: 48%
    left: auto
    text-align: center
    width: 100%
    font-size: 300%
.break
  display: block

.footer
  margin: 50px auto 25px
  // max-width: 1400px
  width: 95%
  text-align: center
  img 
    width: 300px

@media (max-width: 767px)
  .video-player-container, .video-player
    height: 80vh
    iframe
      width: 400vw
    .banner-title
      display: block
      top: 50vh
      left: 20px
      font-size: 30px
      .large
        display: block
        font-size: 30px
        font-weight: 800
        letter-spacing: 2px