.imageTextContainer
  padding: 120px 0
  .contentWrapper
    max-width: 1300px !important
  .contentWrapperSmall
    max-width: 1000px !important
  .imageTextImageContainer
    display: inline-block
    vertical-align: top
    width: 40%
    img
      width: 100%
      height: 450px
      object-fit: cover
      object-position: center
      background-color: #eee
      &.verticalImage
        height: auto
  .imageTextTextContainer
    margin-top: 40px
    display: inline-block
    vertical-align: top
    width: calc(60% - 80px)
    padding: 0 40px
    direction: ltr

.rtl
  direction: rtl

@media (max-width: 768px)
  .imageTextContainer
    padding: 60px 0
    .imageTextTextContainer, .imageTextImageContainer
      width: 100%
      padding: 0