.heroBannerContainer
  width: 100%
  height: 65vh
  position: relative

  .heroBannerContent
    position: absolute
    width: 100%
    max-width: 500px
    height: 100%
    top: 0
    left: 0
    right: 0
    margin: 0 auto
    text-align: center
    display: flex
    align-items: center
    justify-content: center

  img
    width: 100%
    height: 100%
    object-fit: cover
    object-position: center
    background-color: #eee