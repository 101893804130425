body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style:circle;
  text-decoration: none;
}

body {
  margin: 0;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: "Helvetica Neue";
}

p {
  line-height: 1.4;
  letter-spacing: 0.3;
  font-weight: 300;
}

.content-container {
  width: 100%;
  margin: 0 auto;
}

.full-page-container {
  width: 100vw;
  min-height: calc(100vh - 315px);
  padding: 120px 0 75px;
  position: relative;
  /* overflow: hidden; */
}

.homeWrapper .full-page-container {
  width: 100vw;
  min-height: 0 !important;
  height: 100%;
  /* height: 400px; */
  /* max-height: -webkit-fill-available; */
  position: relative;
  padding: 0;
}

.page {
  width: calc(100% - 80px);
  height: calc(100vh - 80px);
  margin: 0px auto;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
}

body {
  margin: 0;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: "Helvetica Neue";
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

p {
  line-height: 1.4;
  letter-spacing: 0.3;
  font-weight: 400;
}

.heading-2 {
  font-size: 36px;
}

.large-title {
  font-weight: 600;
}

.paragraph-container  h1, .paragraph-container h2, .paragraph-container h3,.paragraph-container h4 {
  margin-bottom: 30px;
}

.accent {
  color: #fff;
}

.content-wrapper {
  width: calc(100% - 80px);
  max-width: 1300px;
  margin: 0 auto;
}

.content-wrapper-small {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.paragraph-container.Center {
  text-align: center;
}

.page {
  width: calc(100% - 80px);
  height: calc(100vh - 80px);
  margin: 0px auto;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
}

.navigation {
  width: calc(100% - 80px);
  top: 40px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
  z-index: 1;
  align-items: center;
}

.navigation img {
  width: auto;
}

.side-navigation {
  position: relative;
  z-index: 10;
}
.side-navigation .menu {
  position: fixed;
  width: 375px;
  height: 100vh;
  margin-left: auto;
  color: #fff;
  background-color: #00210d;
  right: 0;
  transform: translateX(375px);
  top: 0;
  transition: transform .4s;
  z-index: 1;
}

.side-navigation.active .menu {
  transform: translateX(0px);
}

.side-navigation.active .overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.side-navigation .menu ul {
  list-style: none;
  padding: 100px 60px 60px;
  margin: 0;
}

.side-navigation .menu ul li {
  margin-top: 25px;
}

.side-navigation .menu ul li, .side-navigation .menu ul a {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.navigation .burger-container {
  width: 30px;
  height: 25px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr;
}

.navigation .burger-container span {
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.slider-icons {
  position: fixed;
  top: 50%;
  right: 40px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px;
}

.slider-icons span {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 100px;
}

.slider-icons span:nth-child(1) {
  background-color: #fff;
}

.page-wrapper {
  margin-top: -27px;
}

.full-page-container .content-container {
  width: 100%;
}

.full-page-container .content-container h1 {
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  font-size: 40px;
}

.full-page-container .content-container h1 span {
  font-size: 76px;
}

.full-page-container .content-container .home-link-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.full-page-container .content-container .home-link-container .home-link-item {
  margin: 0 30px;
  width: 300px;
  color: #fff;
  font-size: 14px;
}

.full-page-container .content-container .home-link-container .home-link-item:nth-child(1) {
  margin-left: 0px;
}

.full-page-container .content-container .home-link-container .home-link-item img {
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
  object-fit: cover;
  object-position: center;
  transition: all 1s;
}
/* 
.full-page-container .content-container .home-link-container .home-link-item img:hover {
  width: 110%;
  transform: translate(-5%, -20px);
  height: 220px;
} */

.full-page-container .content-container .home-link-container .home-link-item h2 {
  font-size: 14px;
  margin-bottom: 15px;
}

.full-page-container .content-container .home-link-container .home-link-item h2, .full-page-container .content-container .home-link-container .home-link-item p, .full-page-container .content-container .home-link-container .home-link-item a {
  text-decoration: none;
  color: #fff;
}

.footer-container {
  position: fixed;
  width: calc(100% - 80px);
  bottom: 40px;
  left: 40px;
  color: #fff;
  font-size: 12px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.footer-container.default {
  position: relative;
  padding: 30px 40px;
  left: 0;
  bottom: 0;
  background-color: #00210d;
}

.footer-container div:nth-child(2) {
 order: 0
}

.footer-container div:nth-child(1) {
  order: 1;
  flex: 1;
}

.flex-end {
  justify-self: flex-end;
}

.social-icons {
  width: auto;
  height: 25px;
}

.footer-container ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.footer-container ul li {
  margin: 0 25px;
  font-size: 14px;
}

.footer-container ul a {
  color: #fff;
  text-decoration: none;
}

.footer-container ul li:nth-child(1) {
  margin-left: 0px;
}

.slick-slider {
  overflow: hidden;
}

@media screen and (max-width: 450px) {
  .navigation, .footer-container, .footer-container.default {
    width: calc(100% - 40px);
  }

  .navigation {
    top: 20px;
  }

  .footer-container {
    left: 20px;
  }

  .footer-container.default {
    padding-left: 20px;
    padding-right: 20px;
  }

  .logo-container img {
    max-width: 200px;
  }

  .side-navigation .menu {
    max-width: 300px;
  }

  .footer-container {
    bottom: 10px
  }

  .footer-container .flex-end {
    flex: 1;
    justify-self: flex-start;
    margin-bottom: 0;
  }

  .footer-container .flex-end ul {
    margin-bottom: 0;
  }

  .footer-links {
    width: 100%;
    margin-bottom: 20px;
    /* display: block !important; */
  }
}

.full-page-container .background-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.full-page-container .background-container img, .full-page-container .background-container .opacity-overlay {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.full-page-container .background-container .opacity-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
}

.slick-vertical .slick-slide {
  border-width: 0 !important;
}

.slick-dots {
  top: 50%;
  width: 20px;
  right: 40px;
  max-height: 200px;
}

.slick-dots li.slick-active button:before, .slick-dots li button:before {
  color: #fff;
  font-size: 9px;
}

.full-page-container .content-container a, .full-page-container .content-container h2 {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
}

.full-page-container .content-container .button {
  width: 220px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 20px;
  /* background-color: #fff; */
  text-align: center;
  padding-top: 10px;
}

@media screen and (max-width: 765px) {
  .full-page-container .content-container h1 {
    font-size: 25px;
  }

  .slick-dots {
    right: 20px;
  }

  .full-page-container .content-container h1 span {
    font-size: 28px;
  }

  .full-page-container .content-container a, .full-page-container .content-container h2 {
    font-size: 14px !important;
  }

  .full-page-container .content-container .button {
    margin-top: -10px;
  }
}