.gridContainer
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 100px
  .fieldGroup
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 10px
    margin-bottom: 20px
  .fieldSetContainer
    border-radius: 10px
    .fieldLegend
      padding: 0 10px
    .textInput
      width: 100%
      margin-bottom: 5px
      background-color: rgba(0, 0, 0, 0)
      border: none
      outline: none
      color: #fff
    textarea
      font-family: inherit
  .submitButton
    width: 100%
    height: 40px
    background-color: rgba(255, 255, 255, 0)
    border: 1px solid #fff
    border-radius: 10px
    color: #fff
    outline: none
    margin-top: 20px
    transition: all 0.25s
    &:hover
      color: #000
      background-color: rgba(255, 255, 255, 1)
  .success
    margin-bottom: 20px
  .errorMessage
    margin-bottom: 20px
    color: red

@media (max-width: 768px)
  .gridContainer
    grid-template-columns: 1fr
    .fieldGroup
      grid-template-columns: 1fr