//Font
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

.video-banner {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95)
  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}

//Mixins 
//Centering Mixins
@mixin horizontal-center {
  margin-left: auto;
  margin-right: auto;
}

@mixin vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


//Media Query mixins
@mixin mobile {
  @media (min-width: #{$mobile-breakpoint}) and (max-width: #{$large-mobile-breakpoint - 1}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$large-mobile-breakpoint}) and (max-width: #{$tablet-breakpoint - 1}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-breakpoint}) and (max-width: #{$desktop-breakpoint}) {
    @content;
  }
}

//Animations
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
   0% {
    top: -300px;
  }
  100% {
    top: 0;
  }
}

@keyframes scaleDown {
    0% {
    transform: scale(1);
  }
   10% {
    transform: scale(0.7);
  }
   95% {
    transform: scale(0.7);
  }
   100% {
    transform: scale(1);
  }
}

@keyframes reveal-info {
  0% {
    height: 0;
  }
  5% {
    height: 350px;
  }
  95% {
    height: 350px;
  }
  100% {
    height: 0;
  }
}

//Body 

body {
  margin: 0;
  padding: 0;
}


.container {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 90%;
  font-size: 14px;
  font-family: "Montserrat", sans;
  .container-inner { 
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   position: relative;
  //  animation: fade-in 1.5s ease, slide-in 1.2s ease;
  //  -webkit-animation: fade-in 1.5s ease, slide-in 1.2s ease;
  margin-left: -80px;
}

.two-col {
  column-count: 2;
  column-gap: 80px
}

.flex {
  margin-top: 50px;
  display: flex;
}

.flex-item {
  flex: 1;
  padding: 0 30px;
  h3 {
    color: #8f7d45;
  }
}

.flex-item:first-of-type {
  padding-left: 0px;
}

.main-content {
    flex-basis: 50%;
    order: 1;
    align-self: center;
    display: flex;
    flex-direction: column;
    p {
      line-height: 2;
    }
    .main-headings { 
      width: 100%;
      align-self: center;
      display: flex;
      flex-direction: column;
      // animation: scaleDown 6s ease 2s;
    //  -webkit-animation: scaleDown 6s ease 2s;
      #by-line {
        padding-bottom: 1px;     
        font-size: 85%;
        border-bottom: 2px solid #8f7d45;       
        font-style: italic;
      }
      .heading {
        text-transform: uppercase;
       // align-self: flex-end;
         margin-top: 20px;
        #heading1, #heading2, #heading3 {
          margin: 0;
          margin-top: -20px;
        }
        #heading1, #heading2 {
          font-size: 500%;
          font-weight:normal;
        }
        #heading2 {
          font-size: 20px;
          margin-bottom: 20px;
          color: #8f7d45;
        }
        #heading3 {
          margin-left: 50px;
          margin-top: -52px;
          font-size: 1100%;
          font-weight: 800;
          letter-spacing: 2px;
          color: #8f7d45;
        }
      }
      .short-bio {
        width: 60%;
        margin-top: -30px;
        margin-left: 120px;
        font-style: italic;
        font-size: 105%;
      }
      a {
       // width: 72%;
        margin-left: 120px;
       // margin-right: auto;
        align-self: flex-start;
        font-weight: bold;
        font-size: 120%;
        font-family: helvetica;
        text-decoration: none;
        color: black;
        &:hover {
          text-decoration: underline;
        }
      }  
    }  
     .more-info {
        margin-top: -70px;
        width: 50%;
        height: 0;
        margin-left: auto;
        margin-right: auto; 
        align-self: flex-start;
        overflow: hidden;
      //   animation: reveal-info 4s ease 3s;
      //  -webkit-animation: reveal-info 4s ease 3s;
     }
  }
  .image-wrapper {
    order: 2;
    left: 50px;
    position: relative;
    flex-basis: 50%; 
    //background-color was #8D6E63
    .image-container {
      width: 110%;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    //   animation: scaleDown 6s ease 2s ;
    //  -webkit-animation: scaleDown 6s ease 2s;
      img {
        width: 100%;
        height: 70%;
        object-fit: cover;
        object-position: center;
        position: relative;
       }
      }
    }     
 }


@media (max-width: 767px) {
  #heading2 {
    font-size: 14px !important;
    font-weight: bold !important;
  }

  .two-col {
    column-count: 1 !important;
  }

  #heading1 {
    font-size: 38px !important;
  }

  .container {
    width: calc(100% - 50px);
  }

  .banner-title {
    left: 25px !important;
  }

  .video-player-container.thin {
    margin-top: 40px !important;
    margin-bottom: 20px !important;
    .banner-title {
      left: 0 !important;
    }
  }
  
  #sustainability {
    margin-top: 0 !important;
    .flex {
      display: block;
      margin: 0;
      .flex-item {
        padding: 0;
        margin: 0;
        margin-bottom: 35px;
      }
    }
  }
}